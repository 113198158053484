import React from 'react';

import ChevronIcon from '$assets/downarrow.svg';

const FAQ = ({ id, title, description }) => (
  <div className='border-b-2 border-gray-100'>
    <input type='radio' name='faq' id={`faq-${id}`} className='peer hidden' />

    <label
      htmlFor={`faq-${id}`}
      className='p-3 flex justify-between cursor-pointer peer-checked:text-primary transition-colors hover:bg-gray-100'>
      <h3 className='text-xl'>{title}</h3>
      <ChevronIcon className='w-4 fill-current' />
    </label>

    <p className='col-start-2 px-3 h-0 overflow-hidden duration-500 transition-all peer-checked:h-16 peer-checked:phone:h-24'>
      {description}
    </p>
  </div>
);

export default FAQ;
