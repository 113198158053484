import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";

import Section from "$ui/section";
import Heading from "$ui/heading";

import Faq from "$components/faq";

import SearchIcon from "$assets/search.svg";

const faqsData = [
  {
    id: 0,
    title: "Who Can file A Complaint through this portal?",
    description: `Any citizen of India can file a complaint after becoming a member of the organization`,
  },
  {
    id: 1,
    title: "When can a consumer file his complaint?",
    description: `When a customer is unsatisfied by the service of any service provider or seller, when defects are detected in concerned products or service or when a customer is cheated by a seller  then a complaint can be registered.`,
  },
  {
    id: 2,
    title: "What is the charge of membership fees?",
    description: `The annual membership fees of consumer rights organisation is Rs 500 (In words five hundred rupees only) .
`,
  },
  {
    id: 3,
    title: "How many complaints can be filed by a member?",
    description: `A member can lodge any number of complaints in a year, but complaints should be named with his
identity as per our membership record.
`,
  },
  {
    id: 4,
    title: "How do I Register my Complaint ?",
    description: `After registering as a member, You need to share your details mentioned in the
complaint form and upload the required documents for the assessment of the
complaint. Our System will accept the case and you can check the status of the complaint
with login details
`,
  },
  {
    id: 5,
    title: "How will I know the status of my case?",
    description: `A login is provided to you at the time of registration which shall have the status of case
movement in your dashboard. We also provide regular updates through email
`,
  },
  {
    id: 6,
    title: "How much time does it take to resolve my complaint?",
    description: `Depending upon the merit of the case, usually it takes 30- 45 daysFAQ to resolve.
However, if we require the case to move to consumer commission it may take 4- 6
months depending on the availability of dates for hearing on the case
`,
  },
  {
    id: 7,
    title: "How do I make sure that my complaint has been Registered ?",
    description: `After registering your complaint you will get a consent mail on your provided mail id within 48
(working) hours
`,
  },
  {
    id: 8,
    title: "Where can I file my consumer complaint?",
    description: `You can drop your complaint through our dedicated complaint portal available at www.thecro.org

`,
  },
  //   {
  //     id: 9,
  //     title: "How the consumer rights organisation team resolves my complaint?",
  //     description: `Once you drop your complaint in our portal, immediately a notice will be prepared and sent to
  // the company/vendor/organisation. In social media we run campaign regarding the complaint for
  // redressal on behalf of consumer, we will contact with opposite party and we will try to resolve
  // out of court . After notice period our expertise legal team prepares to file case in consumer
  // forum with minimal applicable legal , affidavit , etc charges based on the case.

  // `,
  //   },
];

const FAQPage = () => {
  const [faqs, setFaqs] = useState(faqsData);

  const filterFAQs = useCallback((e) => {
    setFaqs(
      faqsData.filter((f) =>
        `${f.description} ${f.title}`.includes(e.target.value)
      )
    );
  }, []);

  return (
    <main>
      <Helmet>
        <title>TheCRO &mdash; FAQs</title>
      </Helmet>

      <Section className="pt-32 h-[55vh] bg-blend-overlay bg-primary bg-[url($images/faqs/background.webp)] flex flex-col justify-center items-center space-y-6">
        <Heading variant="secondary-white">We're here to help!</Heading>

        <div className="w-2/3 flex items-center">
          <input
            placeholder="Search for help"
            className="w-full h-10 py-2 px-4 outline-none shadow-sm rounded-md"
            onChange={filterFAQs}
          />

          <SearchIcon className="fill-gray-400 h-4 w-4 -ml-10" />
        </div>
      </Section>

      <Section className="bg-gray-100">
        <div className="bg-white min-h-[30rem] w-4/5 mx-auto -mt-40 shadow-md rounded-md overflow-x-hidden phone:w-11/12">
          {faqs.map((f) => (
            <Faq
              key={f.id}
              id={f.id}
              title={f.title}
              description={f.description}
            />
          ))}
        </div>
      </Section>
    </main>
  );
};

export default FAQPage;
